













import { Vue, Component } from "vue-property-decorator";

@Component({
    components: {
        CurrentWeather: () =>
            import("@/pages/components/aemet/CurrentWeather.vue"),
        RangeWeather: () => import("@/pages/components/aemet/RangeWeather.vue")
    }
})
export default class Aemet extends Vue {
    setProgressBar!: (state: boolean) => void;
    setInfoMessage!: (state: { shown: boolean; text: string | null }) => void;

    /**
     * Show error message
     * @param {string} error Error message
     * @return {void}
     */
    showError(error: string): void {
        this.setInfoMessage({ shown: true, text: error });
    }
}
